<template>
  <v-container fluid>
        
    <!--acerca de-->
    <v-row :class="sectionHero">
      <div :class="sectionOne">
        <v-row align="center">

          <v-col class="text-left" cols="12"  v-if="$vuetify.breakpoint.lgAndUp">
            <h1 class="title-page-index">Recaudación Digital</h1>
            <span class="title-page-movil-index">para Gobierno Municipal</span><br>
            <h3 class="text-description">Nueva plataforma que permite a las haciendas públicas aumentar sus canales de pago</h3>
            <h3 class="text-description">de forma rápida y sin complicaciones.</h3><br>
            <v-btn rounded color="success" href="#contacto">Conoce más</v-btn><br><br>
            <v-btn rounded dark color="#CE0294" style="opacity: 100;" href="https://gobinet.mx" target="_blank"> <span> Ver Sistema de Trámites en Línea</span> </v-btn>
            <!--<v-card style="border-radius: 15px;" href="https://gobinet.mx" target="_blank" max-width="250" elevation="20" class="card-gobinet">
              <div class="d-flex flex-no-wrap">
                <img class="img-gobinet" width="60" height="52" src="imgs/gobinetlogo.png"/>
                <div class="text-left text-card-gobinet">
                  <span class="text-white">Conoce aquí la nueva <br> plataforma de pagos <br> y trámites en línea</span>
                </div>
              </div>
            </v-card>-->
          </v-col>
          <v-col class="text-center" cols="12" style="padding-top:120px;" v-if="$vuetify.breakpoint.mdAndDown">
            <span class="title-page-movil-index">Recaudación Digital</span><br>
            <span class="title-page-movil-index">para Gobierno Municipal</span><br>
            <h3 class="text-description-index">Nueva plataforma que permite a las haciendas públicas aumentar sus canales de pago</h3>
            <h3 class="text-description-index">de forma rápida y sin complicaciones.</h3><br>
            <v-btn rounded dark color="#CE0294" style="opacity: 100;" href="https://gobinet.mx" target="_blank"> <span> Ver Sistema de Trámites en Línea</span> </v-btn>
            <!--<v-btn rounded color="success" href="#contacto">Conoce más</v-btn><br>--><br>
          </v-col>
        </v-row><br>

      </div>
    </v-row>

    <!-- efecto del encabezado -->
    <CurveShape :class="noHeight" v-if="$vuetify.breakpoint.mdAndUp">
      <template v-slot:bgcolor>
          <path style="fill: #FFFFFF;
              stroke-miterlimit:10;" 
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                  S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                  S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>

    <!-- efecto del encabezado -->
    <CurveShape class="no-height-sm" v-if="$vuetify.breakpoint.smAndDown">
      <template v-slot:bgcolor>
          <path style="fill: #FFFFFF;
              stroke-miterlimit:10;" 
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                  S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                  S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>

    <div :class="sectionSlogan">
      <v-divider></v-divider>

      <v-row class="text-center text-slogan">
        <v-col>
          <span >SIPREM®, "Nuevo conjunto de soluciones tecnológicas que estan mejorando la administración pública en beneficio de los contribuyentes."</span>
        </v-col>
      </v-row>

      <v-divider></v-divider>
    </div>


    <!--beneficios-->    
    <div :class="sectionTwo">

      <div class="title-beneficios" v-intersect="{ handler: onShowTitleBeneficios, options: { threshold: 1.0} }">
        <transition name="slide-vertical">
          <v-row align="center" justify="center" v-show="showTitleBeneficios">
            <p class="text-subtitles">Beneficios</p>
          </v-row>
        </transition>
      </div><br><br>

      <v-row>

        <v-col class="col-beneficio-idx" lg="4" cols="12" v-intersect="{ handler: onShowBeneficio1, options: { threshold: 0.8 } }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showBeneficio1">
              <v-card shaped max-width="300" elevation="6">
                <v-row align="center" justify="center">
                  <div class="img-supposition-squad"> 
                    <img width="55px;" src="icons/ok_white.png"/>
                  </div>
                </v-row>
                <v-row justify="center" class="margin-sup-card">
                  <strong class="title-card">Fácil</strong>
                </v-row>
                <div class="text-center text-content-card">
                  Lo mejor en tecnología con la mayor facilidad de uso. Ahorra hasta el 80% del tiempo en que realizas cada tarea.
                </div>
              </v-card>
            </v-row>
          </transition>
        </v-col>

        <v-col class="col-beneficio-idx" lg="4" cols="12" v-intersect="{ handler: onShowBeneficio2, options: { threshold: 0.8 } }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showBeneficio2">
              <v-card shaped max-width="300" elevation="6">
                <v-row align="center" justify="center">
                  <div class="img-supposition-squad"> 
                    <img width="55px;" src="icons/palacio_white.png"/>
                  </div>
                </v-row>
                <v-row justify="center" class="margin-sup-card">
                  <strong class="title-card">Completo</strong>
                </v-row>
                <div class="text-center text-content-card">
                  Nuevo software que contiene todo lo que un gobierno necesita y corrige las malas prácticas de los sistemas actuales.
                </div>
              </v-card>
            </v-row>
          </transition>
        </v-col>

        <v-col class="col-beneficio-idx" lg="4" cols="12" v-intersect="{ handler: onShowBeneficio3, options: { threshold: 0.8 } }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showBeneficio3">
              <v-card shaped max-width="300" elevation="6">
                <v-row align="center" justify="center">
                  <div class="img-supposition-squad"> 
                    <img width="55px;" src="icons/cronometro_white.png"/>
                  </div>
                </v-row>
                <v-row justify="center" class="margin-sup-card">
                  <strong class="title-card">Rápido</strong>
                </v-row>
                <div class="text-center text-content-card">
                  Contrata y recibe en cuestión de horas la instalación del sistema. Migramos tu información de manera rápida y confiable.
                </div>
              </v-card>
            </v-row>
          </transition>
        </v-col>

      </v-row>
    </div>

    <!--medios de pago-->
    <div><br>

      <v-divider class="dividers"></v-divider><br>

      <div class="title-medios-pago" v-intersect="{ handler: onShowTitleMediosPago, options: { threshold: 1.0 } }">
        <transition name="slide-vertical">
          <v-row align="center" justify="center" v-if="showTitleMediosPagos">
            <p class="text-subtitles">Medios de Pago</p>
          </v-row>
        </transition>
      </div>

      <div >
      <v-row class="section-medios-pago">

        <v-col class="col-medios-pago" lg="3" md="6" cols="12" v-intersect="{ handler: onShowPagosEnLinea, options: { threshold: 0.8} }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showInfoPagosEnLinea">
              <v-row justify="center">
                <a @click="showPanelPagoEnLinea"><strong class="text-img-medios-pago">En Línea</strong></a>
              </v-row>
              <div class="cards" style="width:90%;">
                <a @click="showPanelPagoEnLinea"><v-img src="imgs/enlinea.jpg" class="img-medios-pago"></v-img></a>
              </div>
              <v-row justify="center">
                <a @click="showPanelPagoEnLinea"><strong class="text-medios-pago">Ver Detalles</strong></a>
              </v-row>
            </v-row>
          </transition>
        </v-col>

        <v-col class="col-medios-pago" lg="3" md="6" cols="12" v-intersect="{ handler: onShowPagosCajero, options: { threshold: 0.8} }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showInfoPagosCajeros">
              <v-row justify="center">
                <a @click="showPanelPagoCajero"><strong class="text-img-medios-pago">Kiosco de Pago</strong></a>
              </v-row>
              <div class="cards" style="width:90%;">
                <a @click="showPanelPagoCajero"><v-img src="imgs/cajero.jpg" class="img-medios-pago"></v-img></a>
              </div>
              <v-row justify="center">
                <a @click="showPanelPagoCajero"><strong class="text-medios-pago">Ver Detalles</strong></a>
              </v-row>
            </v-row>
          </transition>
        </v-col>

        <v-col class="col-medios-pago" lg="3" md="6" cols="12" v-intersect="{ handler: onShowPagosBancos, options: { threshold: 0.8} }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showInfoPagosBancos">
              <v-row justify="center">
                <a @click="showPanelPagoTiendasBancos"><strong class="text-img-medios-pago">Bancos y Tiendas</strong></a>
              </v-row>
              <div class="cards" style="width:90%;">
                <a @click="showPanelPagoTiendasBancos"><v-img src="imgs/tienda.jpg" class="img-medios-pago"></v-img></a>
              </div>
              <v-row justify="center">
                <a @click="showPanelPagoTiendasBancos"><strong class="text-medios-pago">Ver Detalles</strong></a>
              </v-row>
            </v-row>
          </transition>
        </v-col>

        <v-col class="col-medios-pago" lg="3" md="6" cols="12" v-intersect="{ handler: onShowPagosTesoreria, options: { threshold: 0.8} }">
          <transition name="slide-fade">
            <v-row justify="center" v-show="showPagosTesoreria">
              <v-row justify="center">
                <a @click="showPanelPagoTesoreria"><strong class="text-img-medios-pago">Fuera de Oficina</strong></a>
              </v-row>
              <div class="cards" style="width:90%;">
                <a @click="showPanelPagoTesoreria"><v-img src="imgs/movil.jpg" class="img-medios-pago"></v-img></a>
              </div>
              <v-row justify="center">
                <a @click="showPanelPagoTesoreria"><strong class="text-medios-pago">Ver Detalles</strong></a>
              </v-row>
            </v-row>
          </transition>
        </v-col>

      </v-row>
      </div>

      <v-divider class="dividers"></v-divider>
      </div><br>

      <!--caracteristicas-->

      <div :class="sectionCaracteristicas">
        <div class="title-caracteristicas" v-intersect="{ handler: onShowTitleCaracteristicas, options: { threshold: 1.0 } }">
          <transition name="slide-vertical">
            <v-row align="center" justify="center" v-if="showTitleCaracteristicas">
              <p class="text-subtitles">Características</p>
            </v-row>
          </transition>
        </div>

        <div>
          <v-row justify="center">

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica2, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica2">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-2px;" src="icons/hand_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Trámite Digital</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Ofrece la opción de realizar pagos, trámites y servicios desde internet todos los días a cualquier hora. 
                    </div>
                  </v-card>
                </v-row>
              </transition> 
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica3, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica3">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px;" src="icons/hand_movil_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Monitoreo Remoto</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Administra y controla toda la actividad de recaudación desde la comodidad de tu celular, tablet o computadora.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica4, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica4">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:26px; margin-left:-1px;" src="icons/candado_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Permisos de usuario</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Define que usuarios pueden cobrar, editar información, ver reportes, cancelar recibos, crea filtros de acceso y más.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica5, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica5">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px;" src="icons/maps_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Cartografía Municipal</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Sistema de recaudación con mapas satelitales, ubica a cada contribuyente y muestra el rezago por zonas.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>
            
             <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica1, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica1">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:35px; margin-left:-1px;" src="icons/personas_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Padrones</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Administra el padrón predial, agua potable, comercio, panteones y más. Todo desde el mismo sistema.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica6, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica6">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-1px;" src="icons/folder_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Expediente Digital</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Digitaliza el expediente de cada contribuyente y consulta automáticamente desde cualquier lugar 
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica7, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica7">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-1px;" src="icons/doc_digital_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Documentos Digitales</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Ahorra tiempo y genera certificaciones, estados de cuenta, licencias de funcionamiento etc.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica8, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica8">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-1px;" src="icons/sat_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Facturación</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Timbra, cancela y envía al instante, todo desde el mismo sistema sin el uso de aplicaciones externas.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica9, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica9">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:27px; margin-left:-3px;" src="icons/operaciones_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Cálculo Automático</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Impuestos, Contribuciones, Recargos, Multas, Descuentos etc. Todo calculado con estricto apego al marco legal .
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>
            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica10, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica10">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-3px;" src="icons/web_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Cobertura Total</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Acceso a todo el sistema desde cualquier parte del mundo y con alta disponibilidad del servicio.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica11, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica11">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-1px;" src="icons/huella_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">Acceso Biométrico</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Sistema blindado con tecnología biométrica para la correcta protección de la información.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

            <v-col class="col-caracteristicas-idx" lg="3" md="4" sm="6" cols="12" v-intersect="{ handler: onShowCaracteristica12, options: { threshold: 0.9 } }">
              <transition name="slide-fade">
                <v-row justify="center" v-if="showCaracteristica12">
                  <v-card shaped max-width="250" elevation="4">
                    <v-row align="center" justify="center">
                      <div class="img-supposition-rounded">
                        <div class="img-supposition-ball"> 
                          <img width="45px;" style="margin-top:25px; margin-left:-1px;" src="icons/engranes_white.png" />
                        </div>
                      </div>
                    </v-row>
                    <v-row justify="center" class="margin-sup-card">
                      <strong class="title-card">+100 Funcionalidades</strong>
                    </v-row>
                    <div class="text-center text-content-card">
                      Único sistema en México con las mejores características en el mercado que están transformando al país.
                    </div>
                  </v-card>
                </v-row>
              </transition>
            </v-col>

          </v-row>

        </div>
        <br><v-divider class="dividers"></v-divider><br>
      </div>

      <!-- contadores/estadisticas -->
      <div class="text-center" style="margin-bottom:170px;" >
        <span class="title-facts">Sin historias, solo hechos</span><br>
        <v-row justify="center" style="margin-top:80px;">
          <v-col xl="2" md="3" cols="12" v-intersect="{ handler: onShowFact1, options: { threshold: 0.9 } }">
            <span class="subtitle-facts">desde 2016</span><br>
            <div style="height:90px;">
              <ICountUp v-if="showFact1" class="numbers-facts" endVal="7000000"/>
              <!--<animated-number v-if="showFact1" class="numbers-facts" :value="value1" :formatValue="formatToPrice" :duration="1500" />-->
            </div>
            <span class="text-facts">DE PAGOS PROCESADOS</span>
          </v-col>
          <v-col xl="2" md="3" cols="12" v-intersect="{ handler: onShowFact2, options: { threshold: 0.9 } }">
            <span class="subtitle-facts">desde 2015</span><br>
            <div style="height:90px;">
              <ICountUp v-if="showFact2" class="numbers-facts" endVal="9000000"/>
            </div>
            <span class="text-facts">DE FACTURAS EMITIDAS</span>
          </v-col>
          <v-col xl="2" md="3" cols="12" v-intersect="{ handler: onShowFact3, options: { threshold: 0.9 } }">
            <span class="subtitle-facts">sin caídas</span><br>
            <div style="height:90px;">
              <ICountUp v-if="showFact3" class="numbers-facts" endVal="60000"/>
            </div>
            <span class="text-facts">HORAS EN FUNCIONAMIENTO</span>
          </v-col>
        </v-row>
      </div>

      <!-- flipbook -->
      <div>
        <!--<div class="container-book" :style="heightBook">-->
          <center>
            <span class="title-facts">Revista digital</span><br><br>
            <flipbook align="center" justify="center" :class="flipbook+' container-book'" :pages="$vuetify.breakpoint.smAndUp ? imgsRevistaSm : imgsRevistaSm" v-slot="flipbook" >
              <center style="margin-bottom:0px;">
                <!--<v-card class="buttons-book" :style="styleButtonsBook">-->
                  <v-btn rounded color="success" :style="styleButtonsBook" @click="flipbook.flipLeft"><!--<img width="30px" src="icons/previus.png"/>-->Anterior</v-btn>
                  <v-btn rounded color="success" :style="styleButtonsBook" @click="flipbook.flipRight"><!--<img width="30px" src="icons/next.png"/>-->Siguiente</v-btn>
                <!--</v-card>-->
              </center>
            </flipbook>
          </center>
        <!--</div>-->
      </div>

      <!--preguntas frecuentes-->
      <!--<div class="text-center" style="margin-bottom:170px; margin-top:150px;" >
        <span class="title-facts">Preguntas frecuentes</span><br>
        <v-row style="margin-top:80px;">
          <v-col lg="6" cols="12" class="text-right">
            <img :width="widthImgPreguntas" src="imgs/aeroland-tab-content-image-04.png"/>
          </v-col>
          <v-col lg="6" cols="12" class="text-left">
            <v-expansion-panels dark style="max-width:550px;" accordion>
              <v-expansion-panel style="background-color:#064578; margin-top:15px;">
                <v-expansion-panel-header>¿Este sistema ya se implementa en algún municipio?</v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  Este sistema actualmente se encuentra implementado en el municipio de teotihuacan.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel style="background-color:#064578; margin-top:15px;">
                <v-expansion-panel-header>¿Este sistema ya se implementa en algún municipio?</v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  Este sistema actualmente se encuentra implementado en el municipio de teotihuacan.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel style="background-color:#064578; margin-top:15px;">
                <v-expansion-panel-header>¿Este sistema ya se implementa en algún municipio?</v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  Este sistema actualmente se encuentra implementado en el municipio de teotihuacan.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel style="background-color:#064578; margin-top:15px;">
                <v-expansion-panel-header>¿Este sistema ya se implementa en algún municipio?</v-expansion-panel-header>
                <v-expansion-panel-content class="text-left">
                  Este sistema actualmente se encuentra implementado en el municipio de teotihuacan.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>-->
    
  </v-container>
</template>

<script>
  import Flipbook from 'flipbook-vue';
  import CurveShape from './CurveShape';
  import ICountUp from 'vue-countup-v2';

  export default {
    metaInfo: {
      title: 'SIPREM',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'El sistema de recaudación número 1 para gobierno municipal' },
        { name: 'news_keywords', content: 'sistema, recaudación, número 1, para gobierno municipal' }
      ]
    },
    computed: {
      widthImgPreguntas: function(){
        return this.$vuetify.breakpoint.lgAndUp ? '60%' : '100%';
      },
      styleButtonsBook: function(){
        var style = null;
        if(this.$vuetify.breakpoint.xlOnly){
          style = 'margin-bottom:-80px; box-shadow: 4px 5px 5px 5px #7C7C7C;';
        }else if(this.$vuetify.breakpoint.lgOnly){
          style = 'margin-bottom:-50px; box-shadow: 4px 5px 5px 5px #7C7C7C;';
        }else if(this.$vuetify.breakpoint.mdOnly){
          style = 'margin-bottom:-20px; box-shadow: 4px 5px 5px 5px #7C7C7C;';
        }else if(this.$vuetify.breakpoint.smAndDown){
          style = 'margin-bottom:-40px; box-shadow: 4px 5px 5px 5px #7C7C7C;';
        }
        return style;
      },
      flipbook: function() {
        var classFlipbook = null;
        if(this.$vuetify.breakpoint.xlOnly){
          classFlipbook = "flipbook-xl";
        }else if(this.$vuetify.breakpoint.lgOnly){
          classFlipbook = "flipbook-lg";
        }else if(this.$vuetify.breakpoint.mdOnly){
          classFlipbook = "flipbook-md";
        }else if(this.$vuetify.breakpoint.smAndDown){
          classFlipbook = "flipbook-smAndDown";
        }
        return classFlipbook;
      },
      noHeight: function() {
        var noHeight = 'no-height';
        if(this.$vuetify.breakpoint.lgOnly){
          noHeight= 'no-height-lg';
        }
        if(this.$vuetify.breakpoint.mdOnly){
          noHeight= 'no-height-md';
        }
        return noHeight;
      },
      sectionSlogan: function() {
        return this.$vuetify.breakpoint.lgAndUp ? 'section-slogan-index' : 'section-slogan-index-sm';
      },
      sectionCaracteristicas: function() {
        return this.$vuetify.breakpoint.lgAndUp ? 'section-caracteristicas-xl' : 'section-caracteristicas-lgAndDown';
      },
      sectionHero: function(){
        var sectionHero = 'section-hero';
        if(this.$vuetify.breakpoint.lgOnly){
          sectionHero= 'section-hero-lg';
        }else if(this.$vuetify.breakpoint.mdOnly){
          sectionHero= 'section-hero-md';
        }else if(this.$vuetify.breakpoint.smAndDown){
          sectionHero= 'section-hero-sm';
        }
        return sectionHero;
      },
      sectionOne: function(){
        return this.$vuetify.breakpoint.lgAndUp ? 'section-one-xl-index' : 'section-one-lgAndDown-index';
      },
      sectionTwo: function(){
        return this.$vuetify.breakpoint.lgAndUp ? 'section-two-xl' : 'section-two-lgAndDown';
      }
    },
    components: {
      Flipbook,
      CurveShape,
      ICountUp
    },
    data() {
      return {
        value1: 117695,
        value2: 135927,
        value3: 35000,
        buttonsColor: "#003978",
        iconSize: "70px",
        isButtonDark: true,
        isActive: false,
        offsetTop: 0,
        showTitleMediosPagos: false,
        showInfoPagosEnLinea: false,
        showInfoPagosCajeros: false,
        showInfoPagosBancos: false,
        showPagosTesoreria: false,
        showTitleCaracteristicas: false,
        showCaracteristica1: false,
        showCaracteristica2: false,
        showCaracteristica3: false,
        showCaracteristica4: false,
        showCaracteristica5: false,
        showCaracteristica6: false,
        showCaracteristica7: false,
        showCaracteristica8: false,
        showCaracteristica9: false,
        showCaracteristica10: false,
        showCaracteristica11: false,
        showCaracteristica12: false,
        showTitleBeneficios: false,
        showBeneficio1: false,
        showBeneficio2: false,
        showBeneficio3: false,
        showFact1:false,
        showFact2:false,
        showFact3:false,
        timeOutPagosEnLinea:0,
        timeOutPagosCajero:0,
        timeOutPagosBanco:0,
        timeOutPagosTesoreria:0,
        isShowDialogRevista:false,
        isShowRevista:false,
        imgsRevistaSm:[
                      null,
                      'imgs/revista/revista_sm/sipremrgb_portada.png',
                      'imgs/revista/revista_sm/sipremrgb_1.png',
                      'imgs/revista/revista_sm/sipremrgb_2.png',
                      'imgs/revista/revista_sm/sipremrgb_3.png',
                      'imgs/revista/revista_sm/sipremrgb_4.png',
                      'imgs/revista/revista_sm/sipremrgb_5.png',
                      'imgs/revista/revista_sm/sipremrgb_6.png',
                      'imgs/revista/revista_sm/sipremrgb_7.png',
                      'imgs/revista/revista_sm/sipremrgb_8.png',
                      'imgs/revista/revista_sm/sipremrgb_9.png',
                      'imgs/revista/revista_sm/sipremrgb_10.png',
                      'imgs/revista/revista_sm/sipremrgb_11.png',
                      'imgs/revista/revista_sm/sipremrgb_12.png',
                      'imgs/revista/revista_sm/sipremrgb_13.png',
                      'imgs/revista/revista_sm/sipremrgb_14.png',
                      'imgs/revista/revista_sm/sipremrgb_contrap.png',
                    ],
        imgsRevista:[
                      null,
                      'imgs/revista/sipremrgb_portada.png',
                      'imgs/revista/sipremrgb_1.png',
                      'imgs/revista/sipremrgb_2.png',
                      'imgs/revista/sipremrgb_3.png',
                      'imgs/revista/sipremrgb_4.png',
                      'imgs/revista/sipremrgb_5.png',
                      'imgs/revista/sipremrgb_6.png',
                      'imgs/revista/sipremrgb_7.png',
                      'imgs/revista/sipremrgb_8.png',
                      'imgs/revista/sipremrgb_9.png',
                      'imgs/revista/sipremrgb_10.png',
                      'imgs/revista/sipremrgb_11.png',
                      'imgs/revista/sipremrgb_12.png',
                      'imgs/revista/sipremrgb_13.png',
                      'imgs/revista/sipremrgb_14.png',
                      'imgs/revista/sipremrgb_contrap.png',
                    ],
      }
    },
    created () {
      window.scrollTo(0, 0);
    },
    methods: {
      formatToPrice(value) {
        /*return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");*/
        /*value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");*/
        return `${value.toFixed(0)}`;
      },
      hideRevista(){/* Este método se manda ha llamar cuando se da click en el boton "X" (cerrar revista) */
        this.isShowDialogRevista=false;
      },
      showRevista(){/* Este método se manda ha llamar cuando se da click en la imagen(portada de revista informativa), o el boton(ver revista) */
        this.isShowDialogRevista=true;
        setTimeout(() => this.isShowRevista=true, 100);
      },
      onShowFact1(entries){
        this.showFact1 = entries[0].isIntersecting;
      },
      onShowFact2(entries){
        this.showFact2 = entries[0].isIntersecting;
      },
      onShowFact3(entries){
        this.showFact3 = entries[0].isIntersecting;
      },
      onShowBeneficio3(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "3" del sistema esta en pantalla */
        this.showBeneficio3 = entries[0].isIntersecting;
      },
      onShowBeneficio2(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "2" del sistema esta en pantalla */
        this.showBeneficio2 = entries[0].isIntersecting;
      },
      onShowBeneficio1(entries){/* Se manda a llamar cuando el div que contiene la información del beneficio "1" del sistema esta en pantalla */
        this.showBeneficio1 = entries[0].isIntersecting;
      },
      onShowTitleBeneficios(entries){/* Se manda a llamar cuando el titulo "Beneficios" esta en pantalla */
        this.showTitleBeneficios = entries[0].isIntersecting;
      },
      onShowCaracteristica12(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "12" del sistema esta en pantalla */
        this.showCaracteristica12 = entries[0].isIntersecting;
      },
      onShowCaracteristica11(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "11" del sistema esta en pantalla */
        this.showCaracteristica11 = entries[0].isIntersecting;
      },
      onShowCaracteristica10(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "10" del sistema esta en pantalla */
        this.showCaracteristica10 = entries[0].isIntersecting;
      },
      onShowCaracteristica9(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "9" del sistema esta en pantalla */
        this.showCaracteristica9 = entries[0].isIntersecting;
      },
      onShowCaracteristica8(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "8" del sistema esta en pantalla */
        this.showCaracteristica8 = entries[0].isIntersecting;
      },
      onShowCaracteristica7(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "7" del sistema esta en pantalla */
        this.showCaracteristica7 = entries[0].isIntersecting;
      },
      onShowCaracteristica6(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "6" del sistema esta en pantalla */
        this.showCaracteristica6 = entries[0].isIntersecting;
      },
      onShowCaracteristica5(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "5" del sistema esta en pantalla */
        this.showCaracteristica5 = entries[0].isIntersecting;
      },
      onShowCaracteristica4(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "4" del sistema esta en pantalla */
        this.showCaracteristica4 = entries[0].isIntersecting;
      },
      onShowCaracteristica3(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "3" del sistema esta en pantalla */
        this.showCaracteristica3 = entries[0].isIntersecting;
      },
      onShowCaracteristica2(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "2" del sistema esta en pantalla */
        this.showCaracteristica2 = entries[0].isIntersecting;
      },
      onShowCaracteristica1(entries){/* Se manda a llamar cuando el div que contiene la información de la característica "1" del sistema esta en pantalla */
        this.showCaracteristica1 = entries[0].isIntersecting;
      },
      onShowPagosTesoreria(entries){/* Se manda a llamar cuando el div que contiene titulo e imagen "Pago fuera de oficina" esta en pantalla */
        this.showPagosTesoreria = entries[0].isIntersecting;
      },
      onShowPagosBancos(entries){/* Se manda a llamar cuando el div que contiene titulo e imagen "Pago en bancos y tiendas" esta en pantalla */
        this.showInfoPagosBancos = entries[0].isIntersecting;
      },
      onShowPagosCajero(entries){/* Se manda a llamar cuando el div que contiene titulo e imagen "Pago en kiosco de pago" esta en pantalla */
        this.showInfoPagosCajeros = entries[0].isIntersecting;
      },
      onShowPagosEnLinea(entries){/* Se manda a llamar cuando el div que contiene titulo e imagen "Pago en linea" esta en pantalla */
        this.showInfoPagosEnLinea = entries[0].isIntersecting;
      },
      onShowTitleMediosPago(entries){/* Se manda a llamar cuando el titulo medios de pago esta en pantalla */
        this.showTitleMediosPagos = entries[0].isIntersecting;
      },
      onShowTitleCaracteristicas(entries){/* Se manda a llamar cuando el titulo características esta en pantalla */
        this.showTitleCaracteristicas = entries[0].isIntersecting;
      },
      showPanelPagoEnLinea(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en linea" */
        this.$router.push('/pagosenlinea');
      },
      showPanelPagoCajero(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en kiosco de pago" */
        this.$router.push('/pagosencajero');
      },
      showPanelPagoTiendasBancos(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago en bancos y tiendas" */
        this.$router.push('/pagosenbancos');
      },
      showPanelPagoTesoreria(){/* Este método se manda ha llamar cuando se da click en el titulo ó imagen "Pago fuera de oficina" */
        this.$router.push('/pagosentesoreria');
      }
    }
  }
</script>
<style scoped>
.section-hero{
  background-image: url('imgs/LANDING-SIPREM.jpg');
  min-height: 950px;
  margin-top:-100px;
  width: 110%;
  background-size: cover;
}
.section-hero-lg{
  background-image: url('imgs/LANDING-SIPREM.jpg');
  min-height: 750px;
  margin-top:-100px;
  width: 110%;
  background-size: cover;
}
.section-hero-md{
  background-image: url('imgs/LANDING-SIPREM.jpg');
  min-height: 700px;
  margin-top:-100px;
  width: 110%;
  background-size: cover;
}
.section-hero-sm{
  background-image: url('imgs/LANDING-SIPREM-SM.jpg');
  min-height: 540px;
  margin-top:-100px;
  width: 110%;
  background-size: cover;
}
.buttons-book{
  width:160px;
}
.container-book{
  border-radius:15px; 
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  background-color:#F1F1F1;
  margin-left: auto; 
  margin-right: auto;
}
.count-facts{
  min-height:220px;
}
.img-medios-pago{
  border-radius: 15px;
  box-shadow: 4px 4px 15px 1px #7C7C7C;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 100px;
  font-size:20px;
  padding:20px;
  width: 40%;
  height: 98px;
  position:absolute;
  left: 75px;
  top:-35px;
  text-align:center;
}
.img-supposition-ball {
  display: block;
  width: 100%;
  height: 98px;
  align-items: center;
  background-color: #1B73B8;
  border-radius: 50%;
  box-shadow: inset -25px -25px 40px rgba(10,10,10,.5);
  background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);  
  background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%);
  position:absolute;
  left: 0px;
  top:0px;
  text-align:center;
}
.img-supposition-squad{
  background: linear-gradient(90deg,rgba(27,115,184,1) 0%, rgba(15,57,105,1) 90%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 8px;
  font-size:20px;
  padding:20px;
  width: 70%;
  height: 98px;
  position:absolute;
  left: 45px;
  top:-35px;
  text-align:center;
}
.margin-sup-card{
  margin-top:80px;
}
.no-height {
  margin-top: -105px;
}
.no-height-lg {
  margin-top: -70px;
}
.no-height-md {
  margin-top: -55px;
}
.no-height-sm{
  margin-top: -20px;
  margin-right: -25px;
}
</style>